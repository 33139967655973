'use client';

import { FaExclamationTriangle } from 'react-icons/fa';

export default function GlobalError({
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center pt-10 space-y-4">
        <div className="text-red-500 text-9xl">
          <FaExclamationTriangle />
        </div>
        <div className="text-2xl">Something went wrong.</div>
        <div className="pt-2 pb-8">
          <button className="btn btn-primary btn-sqr w-full" onClick={() => reset()}>
            Try again
          </button>
        </div>
      </div>
    </div>
  );
}
